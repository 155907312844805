<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="shops"
      :options.sync="pagination"
      :server-items-length="totalShops"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.category="{ item }">
        <td class="text-xs-right">
          <v-select
            :items="categories"
            v-model="item.tierId"
            class="type"
            item-text="name"
            item-value="id"
            single-line
            return-object
            :disabled="loading"
            v-on:change="changeCategory(item.id, item.tierId)"
          >
          </v-select>
        </td>
      </template>
      <template v-slot:item.createDate="{ item }">
        {{ parseDate(item.createDate) }}
      </template>
      <template v-slot:item.country="{ item }">
        <td class="text-xs-right">
          {{
            item.shopLocations != null &&
            item.shopLocations.length > 0 &&
            item.shopLocations[0].city != null
              ? item.shopLocations[0].city.name
              : ""
          }}
          {{ item.country != null ? "(" + item.country.name + ")" : "" }}
        </td>
      </template>
      <template v-slot:item.activated="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.activated"
          @click="activateShop(item.id, item.activated)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.groupShopping="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.groupShopping"
          @click="changeGroupShopping(item.id, item.groupShopping)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.shoppingEvent="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.shoppingEvent"
          @click="changeShoppingEvent(item.id, item.shoppingEvent)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.cityShopping="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.cityShopping"
          @click="changeCityShopping(item.id, item.cityShopping)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.suggestedShopping="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.suggestedShopping"
          @click="changeSuggestedShopping(item.id, item.suggestedShopping)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.redirectUrl="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.redirectUrl"
          @click="changeRedirectUrl(item.id, item.redirectUrl)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.donations="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.donations"
          @click="changeDonations(item.id, item.donations)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.personalSpecialOffer="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.personalSpecialOffer"
          @click="changePersonalSpecialOffer(item.id, item.personalSpecialOffer)"
          :disabled="loading"
        ></v-switch>
      </template>
      <template v-slot:item.banned="{ item }">
        <v-switch
          inset
          color="#4CAF50"
          v-model="item.banned"
          @click="changeBanStatus(item.id, item.banned)"
          :disabled="loading"
        ></v-switch>
      </template>
      <!--      <template v-slot:item.profile="{ item }">
        <v-avatar color="indigo" size="26" rounded>
          <v-icon dark @click="goToShopDialog(item.id)">mdi-shopping</v-icon>
        </v-avatar>
      </template>-->
      <template v-slot:item.subscriptions="{ item }">
        <router-link
          :to="{
            name: 'ShopSubscriptions',
            params: { shopId: item.id, shopName: item.name }
          }"
        >
          <v-avatar color="green accent-4" size="26" rounded>
            <v-icon dark>flaticon-piggy-bank</v-icon>
          </v-avatar>
        </router-link>
      </template>
      <template v-slot:item.promotionSubscription="{ item }">
        <router-link
          :to="{
            name: 'ShopPromotionSubscription',
            params: { shopId: item.id, shopName: item.name }
          }"
        >
          <v-avatar color="blue" size="26" rounded>
            <v-icon dark>mdi-calendar-check</v-icon>
          </v-avatar>
        </router-link>
      </template>
      <!--      <template v-slot:item.edit="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'ShopEdit',
              params: { shopId: item.id, shopName: item.name }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>-->
      <template v-slot:item.walletGift="{ item }">
        <td>
          <router-link
            :to="{
              name: 'ShopWalletGift',
              params: { shopId: item.id, shopName: item.name }
            }"
          >
            <v-avatar color="orange" size="26" rounded>
              <v-icon dark>mdi-gift</v-icon>
            </v-avatar>
          </router-link>
        </td>
      </template>

      <template v-slot:item.cityShopping="{ item }">
        <td>
          <router-link
            :to="{
              name: 'ShopCityShopping',
              params: { shopId: item.id, shopName: item.name }
            }"
          >
            <v-avatar
              v-if="item.shoppingCities.length > 0"
              color="amber darken-4"
              size="26"
              rounded
            >
              <v-icon dark>mdi-city</v-icon>
            </v-avatar>
            <v-avatar
              v-if="item.shoppingCities.length < 1"
              color="amber accent-5"
              size="26"
              rounded
            >
              <v-icon dark>mdi-city</v-icon>
            </v-avatar>
          </router-link>
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
          </template>
          <v-list>
            <router-link
              :to="{
                name: 'ShopTags',
                params: { shopId: item.id }
              }"
            >
              <v-list-item>
                <v-avatar color="green" size="26" rounded class="mr-2">
                  <v-icon dark>mdi-tag</v-icon>
                </v-avatar>
                Shop tags
              </v-list-item>
            </router-link>
            <router-link
              :to="{
                name: 'ShopEdit',
                params: { shopId: item.id, shopName: item.name }
              }"
            >
              <v-list-item>
                <v-avatar color="teal" size="26" rounded class="mr-2">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-avatar>
                Edit shop
              </v-list-item>
            </router-link>
            <v-list-item>
              <v-avatar color="indigo" size="26" rounded class="mr-2">
                <v-icon dark @click="goToShopDialog(item.id)">mdi-shopping</v-icon>
              </v-avatar>
              <span style="cursor: pointer">Shop profile</span>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <!--      <template v-slot:item.tags="{ item }">
        <td>
          <router-link
            :to="{
              name: 'ShopTags',
              params: { shopId: item.id }
            }"
          >
            <v-list-item>
              <v-avatar color="green" size="26" rounded>
                <v-icon dark>mdi-tag</v-icon>
              </v-avatar>
            </v-list-item>
          </router-link>
        </td>
      </template>-->
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-text-field
              v-model="shopId"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="shopName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="shopEmail"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="shopPhoneNumber"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-text-field>
          </td>
          <td>
            <v-select
              v-model="category"
              dense
              hide-details
              :items="categories"
              item-text="name"
              item-value="id"
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-datetime-picker
              :label="$t('date.from')"
              v-model="dateFrom"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
            <v-datetime-picker
              :label="$t('date.to')"
              v-model="dateTo"
              time-format="HH:mm:ss"
            ></v-datetime-picker>
          </td>
          <td>
            <v-autocomplete
              v-model="shopCity"
              :items="userCities"
              :loading="citiesIsLoading"
              :search-input.sync="search"
              clearable
              item-text="name"
              item-value="id"
              label="City"
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search for
                    <strong>cities</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </td>
          <td><v-spacer></v-spacer></td>
          <td><v-spacer></v-spacer></td>
          <td>
            <v-select
              :items="shopActivatedList"
              v-model="shopActivated"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-select
              :items="groupShoppingList"
              v-model="groupShopping"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-select
              :items="shoppingEventList"
              v-model="shoppingEventActivated"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-select
              :items="redirectUrlList"
              v-model="redirectUrl"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-select
              :items="bannedList"
              v-model="banned"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-select
              :items="cityShoppingList"
              v-model="cityShopping"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShops();
              "
            ></v-select>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveShops();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
            <v-spacer class="pt-2 pb-2"></v-spacer>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <!--          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveShops();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>-->
        </tr>
      </template>
    </v-data-table>
    <ShopDialog v-model="showShopDialog" @close="showShopDialog = false" :shop="shop" />
    <div class="table-footer-prepend d-flex pl-2 align-center">
      <v-btn
        :loading="downloadingExcel"
        :disabled="downloadingExcel"
        color="green darken-1"
        class="ma-2 white--text"
        @click="
          loader = 'downloadingExcel';
          downloadExcel();
        "
      >
        {{ $t("pages.shops.activeShopsWithPostCount") }}
        <v-icon right dark>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ShopApiService from "@/core/services/api.service.shop-api";
import ShopDialog from "@/view/pages/ShopDialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import Storage from "vue-ls";
import Vue from "vue";
import { mapGetters } from "vuex";

let options = {
  namespace: "vuejs__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local" // storage name session, local, memory
};

Vue.use(Storage, options);
export default {
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("pages.shops.shops");
    },
    shopActivatedList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    shoppingEventList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    groupShoppingList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    cityShoppingList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    suggestedShoppingList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    redirectUrlList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    donationsList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    personalSpecialOfferList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    bannedList() {
      return [this.$i18n.t("common.all"), this.$i18n.t("common.no"), this.$i18n.t("common.yes")];
    },
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        { text: this.$i18n.t("pages.shops.entityName"), value: "name", width: "5%" },
        { text: this.$i18n.t("pages.shops.email"), value: "email", width: "8%" },
        { text: this.$i18n.t("pages.shops.phoneNumber"), value: "phoneNumber", width: "5%" },
        {
          text: "Shop category",
          value: "category",
          width: "5%",
          align: "center",
          sortable: false
        },
        { text: this.$i18n.t("pages.shops.date"), value: "createDate", width: "5%" },
        { text: this.$i18n.t("pages.shops.cityCountry"), value: "country", sortable: false },
        { text: this.$i18n.t("pages.shops.vatNumber"), value: "vatNumber", sortable: false },
        { text: this.$i18n.t("pages.shops.taxId"), value: "taxId", sortable: false },
        {
          text: this.$i18n.t("pages.shops.activated"),
          value: "activated",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shops.groupShopping"),
          value: "groupShopping",
          width: "5%",
          sortable: false
        },
        {
          text: "Shopping event",
          value: "shoppingEvent",
          width: "5%",
          sortable: true
        },
        {
          text: this.$i18n.t("pages.shops.redirectUrl"),
          value: "redirectUrl",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shops.banned"),
          value: "banned",
          width: "5%",
          sortable: false
        },
        {
          text: this.$i18n.t("pages.shops.cityShopping"),
          value: "cityShopping",
          width: "5%",
          sortable: false
        },
        { text: "", value: "actions", sortable: false, width: "1%" }
        /*  {
          text: this.$i18n.t("pages.shops.shop"),
          value: "profile",
          align: "center",
          sortable: false,
          width: "5%"
        },
        {
          text: this.$i18n.t("pages.shops.tags"),
          value: "tags",
          align: "center",
          sortable: false,
          width: "5%"
        },
        {
          text: this.$i18n.t("pages.shops.edit"),
          value: "edit",
          align: "center",
          sortable: false,
          width: "5%"
        }*/
      ];
    }
  },
  data() {
    return {
      dates: [],
      dateRangeMenu: false,
      modal: false,
      shopId: "",
      shopName: "",
      shopEmail: "",
      shopPhoneNumber: "",
      shopCity: "",
      shopActivated: "",
      shoppingEventActivated: "",
      groupShopping: "",
      cityShopping: "",
      userCities: [],
      citiesIsLoading: false,
      search: "",
      suggestedShopping: "",
      redirectUrl: "",
      donations: "",
      category: "",
      personalSpecialOffer: "",
      banned: "",
      totalShops: 0,
      shops: [],
      loading: true,
      pagination: {},
      showShopDialog: false,
      shop: null,
      dateFrom: null,
      dateTo: null,

      loader: null,
      downloadingExcel: false,
      categories: [],
      categoriesLoading: false
    };
  },
  components: {
    ShopDialog
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShops();
      },
      deep: true
    }
  },
  async created() {
    //await ShopApiService.init();
    this.searchCities();
  },
  async mounted() {
    await ShopApiService.init();
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await this.getCategories();
  },
  methods: {
    searchCities() {
      // Items have already been loaded
      if (this.userCities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.userCities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    downloadExcel() {
      this.downloadingExcel = true;
      const params = this.getRequestParams(
        this.shopName,
        this.shopEmail,
        this.shopPhoneNumber,
        this.dateFrom,
        this.dateTo,
        this.shopCity,
        this.shopActivated,
        this.shoppingEventActivated,
        this.groupShopping,
        this.cityShopping,
        this.suggestedShopping,
        this.redirectUrl,
        this.donations,
        this.personalSpecialOffer,
        this.banned,
        this.shopId
      );
      params.responseType = "blob";
      ApiService.getFileWithParams(`/api/download/excel/activatedShopsWithPostCount`, params)
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Activated shops with post counts";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.downloadingExcel = false));
    },
    parseDate(date) {
      if (date == null) return "";
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    goToShopDialog(id) {
      this.loading = true;
      ApiService.get(`api/shops/${id}`)
        .then(response => {
          this.$log.debug("UserProfile: ", response.data);
          this.shop = response.data;
          this.showShopDialog = true;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    activateShop(id, activate) {
      this.$log.debug("ShopId: " + id + " , activate: " + activate);
      this.loading = true;
      ApiService.patch(`api/shops/${id}`, {
        activated: activate
      })
        .then(response => {
          this.$log.debug("Activated: ", response.data.activated);
          this.retrieveShops();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeRedirectUrl(id, redirectUrl) {
      this.$log.debug("ShopId: " + id + " , redirectUrl: " + redirectUrl);
      this.loading = true;
      ApiService.patch(`api/shops/${id}`, {
        redirectUrl: redirectUrl
      })
        .then(response => {
          this.$log.debug("redirectUrl: ", response.data.redirectUrl);
          this.retrieveShops();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeDonations(id, donations) {
      this.$log.debug("ShopId: " + id + " , donations: " + donations);
      this.loading = true;
      ApiService.patch(`api/shops/${id}`, {
        donations: donations
      })
        .then(response => {
          this.$log.debug("donations: ", response.data.donations);
          this.retrieveShops();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changePersonalSpecialOffer(id, personalSpecialOffer) {
      this.$log.debug("ShopId: " + id + " , personalSpecialOffer: " + personalSpecialOffer);
      this.loading = true;
      ApiService.patch(`api/shops/${id}`, {
        personalSpecialOffer: personalSpecialOffer
      })
        .then(response => {
          this.$log.debug("personalSpecialOffer: ", response.data.personalSpecialOffer);
          this.retrieveShops();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeShoppingEvent(id, shoppingEvent) {
      this.$log.debug("ShopId: " + id + " , shoppingEvent: " + shoppingEvent);
      this.loading = true;
      ApiService.patch(`api/shops/${id}`, {
        shoppingEvent: shoppingEvent
      })
        .then(response => {
          this.$log.debug("personalSpecialOffer: ", response.data.shoppingEvent);
          this.retrieveShops();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeGroupShopping(id, groupShopping) {
      this.$log.debug("ShopId: " + id + " , groupShopping: " + groupShopping);
      this.loading = true;
      if (groupShopping) {
        ShopApiService.put(`/shop-group-shopping-activation/?shopId=${id}`)
          .then(response => {
            this.$log.debug("Group shopping activated: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        ShopApiService.put(`/shop-group-shopping-deactivation/?shopId=${id}`)
          .then(response => {
            this.$log.debug("Group shopping deactivated: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changeBanStatus(id, banned) {
      this.$log.debug("ShopId: " + id + " , banned: " + banned);
      this.loading = true;
      if (banned) {
        ShopApiService.put(`/ban/?shopId=${id}`)
          .then(response => {
            this.$log.debug("Shop banned: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        ShopApiService.put(`/unban/?shopId=${id}`)
          .then(response => {
            this.$log.debug("Shop unbanned: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changeCityShopping(id, cityShopping) {
      this.$log.debug("ShopId: " + id + " , cityShopping: " + cityShopping);
      this.loading = true;
      if (cityShopping) {
        ShopApiService.put(`/shop-city-shopping-activation/?shopId=${id}`)
          .then(response => {
            this.$log.debug("City shopping activated: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        ShopApiService.put(`/shop-city-shopping-deactivation/?shopId=${id}`)
          .then(response => {
            this.$log.debug("City shopping deactivated: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changeSuggestedShopping(id, suggestedShopping) {
      this.$log.debug("ShopId: " + id + " , suggestedShopping: " + suggestedShopping);
      this.loading = true;
      if (suggestedShopping) {
        ShopApiService.put(`/shop-suggested-shopping-activation/?shopId=${id}`)
          .then(response => {
            this.$log.debug("Suggested shopping activated: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        ShopApiService.put(`/shop-suggested-shopping-deactivation/?shopId=${id}`)
          .then(response => {
            this.$log.debug("Suggested shopping deactivated: " + response);
            this.retrieveShops();
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    getRequestParams(
      shopName,
      shopEmail,
      shopPhoneNumber,
      category,
      dateFrom,
      dateTo,
      shopCity,
      shopActivated,
      shoppingEventActivated,
      groupShopping,
      cityShopping,
      suggestedShopping,
      redirectUrl,
      donations,
      personalSpecialOffer,
      banned,
      shopId
    ) {
      let params = {};

      if (shopId) {
        params["shopId"] = shopId;
      }
      if (shopName) {
        params["shopName"] = shopName;
      }
      if (category) {
        params["tierId"] = category;
      }
      if (shopEmail) {
        params["shopEmail"] = shopEmail;
      }

      if (shopPhoneNumber) {
        params["shopPhoneNumber"] = shopPhoneNumber;
      }

      if (dateFrom) {
        params["dateFrom"] = dateFrom;
      }

      if (dateTo) {
        params["dateTo"] = dateTo;
      }

      if (shopCity) {
        params["shopCity"] = shopCity;
      }

      if (shopActivated) {
        params["shopActivated"] = shopActivated;
      }
      if (shoppingEventActivated) {
        params["shoppingEvent"] = shoppingEventActivated;
      }
      if (groupShopping) {
        params["groupShopping"] = groupShopping;
      }

      if (cityShopping) {
        params["cityShopping"] = cityShopping;
      }

      if (suggestedShopping) {
        params["suggestedShopping"] = suggestedShopping;
      }

      if (redirectUrl) {
        params["redirectUrl"] = redirectUrl;
      }

      if (donations) {
        params["donations"] = donations;
      }

      if (personalSpecialOffer) {
        params["personalSpecialOffer"] = personalSpecialOffer;
      }

      if (banned) {
        params["banned"] = banned;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;
      return params;
    },

    clearSearch() {
      (this.shopId = ""),
        (this.shopName = ""),
        (this.shopEmail = ""),
        (this.shopPhoneNumber = ""),
        (this.category = null),
        (this.dates = []),
        (this.shopCity = ""),
        (this.redirectUrl = ""),
        (this.donations = ""),
        (this.personalSpecialOffer = ""),
        (this.banned = ""),
        (this.groupShopping = ""),
        (this.cityShopping = ""),
        (this.shoppingEventActivated = ""),
        (this.suggestedShopping = ""),
        (this.shopActivated = "");
    },
    retrieveShops() {
      const params = this.getRequestParams(
        this.shopName,
        this.shopEmail,
        this.shopPhoneNumber,
        this.category,
        this.dateFrom,
        this.dateTo,
        this.shopCity,
        this.shopActivated,
        this.shoppingEventActivated,
        this.groupShopping,
        this.cityShopping,
        this.suggestedShopping,
        this.redirectUrl,
        this.donations,
        this.personalSpecialOffer,
        this.banned,
        this.shopId
      );

      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/shops", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug(this.$i18n.t("pages.shops.shops"), response.data);
          this.shops = response.data.content;
          this.totalShops = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalShops;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    },

    getCategories() {
      this.categoriesLoading = true;
      return ShopApiService.getCategories()
        .then(res => {
          this.categories = res.data || [];
        })
        .finally(() => {
          this.categoriesLoading = false;
        });
    },
    changeCategory(id, categoryId) {
      this.$log.debug("ShopId: " + id + " , tierId: " + categoryId.id);
      this.loading = true;
      ApiService.patch(`api/shops/${id}`, {
        tierId: categoryId.id
      })
        .then(response => {
          this.$log.debug("override value: ", response.data.categoryId);
          this.info = response.data.categoryId;
          this.retrieveUsers();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

.v-data-table >>> tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

.v-data-table >>> tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
